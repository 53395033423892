"use strict";

let prevBtn = document.querySelector(".c-slots-slider__prev-btn");
let nextBtn = document.querySelector(".c-slots-slider__next-btn");
let track = document.querySelector(".c-slots-slider__slider-track");
let items = document.querySelectorAll(".c-slots-slider__item");
let position = 0;
let itemWidth = window.getComputedStyle(items[0]).width;
let transformTrack = 0;
let transformSlide = 0;
let itemsArr = [];
let clickChecker = 0;

items.forEach((item, index) => {
  itemsArr.push({ slide: item, position: index });
});

let trackWidth = parseInt(itemWidth) * itemsArr.length;

transformTrack = -parseInt(itemWidth);
track.style.transform = `translateX(${transformTrack}px)`;

let nextSlide = function () {
  track.style.transition = `all ease 0.3s`;
  transformTrack += -parseInt(itemWidth);
  track.style.transform = `translateX(${transformTrack}px)`;

  if (clickChecker === 1) {
    if (position + 1 >= itemsArr.length) {
      position = 0;
    } else {
      position++;
    }

    clickChecker = 0;
  }

  itemsArr[position].position += trackWidth;
  itemsArr[
    position
  ].slide.style.transform = `translateX(${itemsArr[position].position}px)`;

  if (position + 1 >= itemsArr.length) {
    position = 0;
  } else {
    position++;
  }

  console.log(itemsArr);
};

let prevSlide = function () {
  track.style.transition = `all ease 0.3s`;
  transformTrack += parseInt(itemWidth);
  track.style.transform = `translateX(${transformTrack}px)`;

  if (clickChecker === 0) {
    if (position - 1 < 0) {
      position = itemsArr.length - 1;
    } else {
      position--;
    }

    clickChecker = 1;
  }

  itemsArr[position].position -= trackWidth;
  itemsArr[
    position
  ].slide.style.transform = `translateX(${itemsArr[position].position}px)`;

  if (position - 1 < 0) {
    position = itemsArr.length - 1;
  } else {
    position--;
  }
};

prevBtn.addEventListener("click", function () {
  prevSlide();
});
nextBtn.addEventListener("click", function () {
  nextSlide();
});
